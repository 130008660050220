.veil {
  position: absolute;
  text-align: center;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($deep, 0.1);
}

#lottie-player {
  position: fixed;
  top: calc(50% - 150px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
}
