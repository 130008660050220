.consent {
  font-family: $main-font-family;
  width: 90vw;
  margin-bottom: 20px;
  margin-left: 5vw;
  margin-right: 5vw;
  border-radius: 24px;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.28);
  background: $light;

  .close-btn {
    background: transparent;
    background-repeat: no-repeat;
    position: absolute;
    border: none;
    outline: none;
    right: 1.5rem;
    top: 1rem;
    color: $primary;
    font-weight: 600;
    font-family: $main-font-family;
    border: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    opacity: 1;
    font-family: verdana;
  }
  .consent-content {
    border-radius: 24px;
    margin-top: 1.25rem;

    @include media-breakpoint-up(md) {
      height: 116px;
      width: 90%;
      margin: auto;
    }
  }
  .content-asset {
    font-size: 14px;
    color: $grey8;
    letter-spacing: 1.5px;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 15px;
    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }
    a {
      color: $primary;
    }
  }
  .button-wrapper {
    display: flex;
    gap: 0.7rem;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up(md) {
      gap: 5rem;
    }
    button {
      color: $primary;
      font-size: 0.9rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 1;
      text-align: right;
      background: transparent;
      border: none;
      padding: 14px 24px;
      border-radius: 24px;
      width: 90%;
      text-align: center;
      white-space: nowrap;
      text-transform: uppercase;
      @include media-breakpoint-up(md) {
        border-radius: 28px;
        padding: 1em;
      }

      &:focus,
      &:active {
        border: none;
        box-shadow: none;
      }
      &.affirm {
        background-color: $primary;
        color: $light;
        text-transform: uppercase;
      }
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      button {
        padding: 0.85em 1em;
        font-size: 0.9rem;
        border-radius: 28px;
      }
    }
  }
}
.consent-body {
  flex: unset;
  min-width: 20%;
  @include media-breakpoint-up(md) {
    padding-right: 5rem;
  }
}
.personalize-tracking-modal {
  font-family: $main-font-family;
  .modal-dialog {
    margin: 0;
    @include media-breakpoint-up(md) {
      margin: 1.75rem auto;
    }
  }

  .close-button {
    position: absolute;
    width: 1rem;
    height: 1.3rem;
    right: 2.5rem;
    top: 2rem;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .modal-dialog {
    max-width: 920px;
  }
  .modal-content {
    border-radius: 16px;
    background-color: $white;
    padding: 1rem;
    gap: 1rem;

    @include media-breakpoint-up(md) {
      padding: 60px 24px 52px;
      min-width: 640px;
      border-radius: 24px;
    }
    .personalize-content {
      margin: 4rem auto;
      padding: 1rem;

      @include media-breakpoint-up(md) {
        width: 83.33333%;
        margin: 2rem auto;
        padding:0 0.625rem;
      }
      a {
        color: $primary;
      }
      .inputs-container {
        display: flex;
        .input-radio {
          display: inline-block;
          padding: 0.5rem;
          cursor: pointer;
          label {
            display: block;
            margin-left: 0.5rem;
            margin-right: 1rem;
            margin-bottom: 0;
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 1.8;
            color: $grey8;
            cursor: pointer;
          }
          input {
            display: none;
            &:checked + *::before {
              background: radial-gradient(#0171cf 0%, #0171cf 40%, transparent 50%, transparent);
              border-color: grey;
            }
            & + *::before {
              content: "";
              display: inline-block;
              vertical-align: bottom;
              width: 1.5rem;
              height: 1.5rem;
              margin-right: 0.3rem;
              border-radius: 50%;
              border-style: solid;
              border-width: 0.1rem;
              border-color: $grey5;
              cursor: pointer;
            }
          }
        }
      }
      h2,
      h3 {
        height: 28px;
        color: #2e2e38;
        font-family: $main-font-family;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 28px;
      }
      p {
        font-size: 0.875rem;
        line-height: 1.8;
        text-align: justify;
        margin-bottom: 0;
        color: $grey8;
      }
    }
    .actions {
      button {
        color: $light;
        background-color: $primary;
        margin-top: 10px;
        text-transform: uppercase;
        float: right;
        padding: 0.85em 1em;
        border-radius: 24px;
        border: none;
        vertical-align: middle;
        font-family: inherit;
        border: 1px solid transparent;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;
        font-size: 0.9rem;
        line-height: 1;
        text-align: center;
      }
    }
  }

  .confirmation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-area img {
      height: 32px;
    }

    .close-button img {
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
  }

  .confirmation-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .confirmation-title {
      color: $grey70;
      font-family: $main-font-family;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;

      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .confirmation-details {
      color: $dark-gray;
      font-family: $main-font-family;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 20px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }

  .vouchers-and-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;

      &.multiple-codes {
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 1.5rem;

        .vouchers-container {
          width: 100%;
          gap: 1.5rem;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .vouchers-container {
    @include media-breakpoint-up(md) {
      width: fit-content;
    }

    .voucher-code {
      border-radius: 8px;
      background-color: $bg3;
      border: thin solid $border1;
      display: flex;
      justify-content: space-between;
      padding: 0.25rem;
    }

    .left-area {
      display: flex;
      flex-direction: column;
      padding: 0.25rem 0.75rem;

      .code-label {
        color: $primary;
        font-family: $main-font-family;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
      }

      .code-value {
        color: $grey70;
        font-family: $main-font-family;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 20px;
      }
    }

    .right-area {
      display: flex;

      .copy-button {
        border-radius: 24px;
        background-color: $primary;
        color: $white;
        font-family: $main-font-family;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 20px;
        text-align: center;
        padding: 0.75rem 2rem;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }

  .actions {
    a {
      border: 2px solid $primary;
      border-radius: 24px;
      display: flex;
      color: $primary;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 20px;
      text-align: center;
      width: 100%;
      justify-content: center;
      padding: 0.75rem 0;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
}
