@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/mixins/breakpoints";
@import "./components/select.scss";

.storepage {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.search-results {
  button:focus {
    outline: none;
  }

  .breadcrumbs {
    margin-top: 0.75rem;
    margin-bottom: 2rem;
  }
  .breadcrumb {
    width: fit-content;
    margin: 0;
    padding: 1rem 0;
    border: none !important;
    color: #333333 !important;
    font-family: $main-font-family;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 18px;
    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .title {
    color: $grey70;
    font-family: $main-font-family;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.5;
  }
  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
  .product-grid {
    padding: 0.5rem;
    .show-more {
      color: $primary;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.39px;
      line-height: 18px;
      text-align: right;
      white-space: nowrap;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .grid-element {
      padding: 0.5rem;
    }
    h2 {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 24px;
      @include media-breakpoint-up(lg) {
        font-size: 28px;
        letter-spacing: 0;
        line-height: 36px;
      }
    }
    .col-lg-6 {
      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        height: 56%;
      }
    }
  }
}
.plp{
  min-height: calc(100vh - 164px);

}
.search-nav {
  margin-bottom: 1em;
  border-bottom: none;

  .nav-tabs-wrapper {
    padding: 0;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    .nav-tabs {
      border-bottom: 0 none;
    }
  }

  .nav-link:focus {
    background-color: $grey1;
  }
}

@include media-breakpoint-up(md) {
  .search-results {
    .title {
      font-size: 28px;
      line-height: 36px;

    }
  }
}

.product-options .quantity {
  position: absolute;
  bottom: 0;
  right: 0;
}

.filters-and-sorting-dialog {
  display: none;
  overflow: unset;
  .reset-container {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.filters-and-sorting-dialog.show {
  .modal-dialog {
    margin: 0;
    height: 100vh;
  }

  .modal-content {
    height: 100%;

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0px;
      margin: 0 16px;

      .title {
        color: $grey70;
        font-family: $main-font-family;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 24px;
      }

      .close {
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
        font-weight: 200;
        margin: 0;
        padding: 0;
        float: none;
      }
    }

    .modal-body {
      flex-grow: 1;
      padding-top: 1.5rem;

      .filters-and-sorting {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .refinements {
          order: 2;
        }

        .sorting {
          order: 1;
        }

        .refinement {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1rem;

          .refinement-elmt {
            .refinement-button {
              display: flex;
              border: none;
              background-color: transparent;
              align-items: center;
              justify-content: center;
              gap: 1rem;

              input {
                width: 20px;
                height: 20px;
              }

              label {
                margin: 0;
                color: $grey70;
                font-family: $main-font-family;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 20px;
              }
            }
          }
        }

        .sorting-items {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .sorting-item {
            display: flex;
            align-items: center;
            gap: 1rem;

            input {
              width: 20px;
              height: 20px;
            }

            label {
              margin: 0;
              color: $grey70;
              font-family: $main-font-family;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 20px;
            }
          }
        }

        .sorting-title,
        .refinement-title {
          color: $grey70;
          font-family: $main-font-family;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .reset-container {
    position: fixed;
    bottom: 1rem;
    width: 40vw;
    left: 4vw;

    button {
      border: 2px solid $primary;
      border-radius: 24px;
      width: 100%;
      height: 48px;
      color: $primary;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 20px;
      text-align: center;

      &.selected {
        opacity: 0.32;
        pointer-events: none;
      }
    }
  }

  .close-container {
    position: fixed;
    width: 40vw;
    bottom: 1rem;
    right: 5vw;

    button {
      height: 48px;
      border-radius: 24px;
      background-color: $primary;
      width: 100%;
      border: none;
      color: $white;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 20px;
      text-align: center;
    }
  }
}

@include media-breakpoint-up(md) {
  .filters-and-sorting-dialog {
    &.modal {
      display: block !important;
      position: relative;
      opacity: 1;
      width: 100%;
      z-index: 1;

      .modal-dialog {
        transform: none;
      }
    }

    .modal-dialog {
      max-width: 100%;
      margin: 0;
    }

    .modal-content {
      border: none;
    }

    .modal-header,
    .modal-footer {
      display: none;
    }

    .modal-body {
      padding: 0;
    }
  }

  .refinements {
    display: flex;
    gap: 1rem;

    .reset-container {
      display: flex;
      order: 1;
    }

    .refinement {
      display: flex;
      margin-bottom: 0;
      list-style: none;
      padding: 0;
      gap: 1rem;
      order: 2;
    }

    .refinement-button {
      border: 2px solid $deep;
      border-radius: 24px;
      color: $deep;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 20px;
      height: 40px;
      padding: 0 1rem;
      background-color: $white;

      &.selected {
        background-color: $deep;
        color: $white;
      }

      label {
        margin: 0;
      }
    }
  }

  .filters-and-sorting {
    justify-content: space-between;
    margin: 2rem 0;
    display: flex;

    .sorting select {
      height: 40px;
      width: auto;
      border: 2px solid $deep;
      border-radius: 24px;
      color: $deep;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 20px;
      padding-left: 10px;
    }
  }
}

.title-container {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  h1 {
    margin: 0;
    cursor: pointer;
  }

  button {
    box-sizing: border-box;
    height: 40px;
    border: 2px solid $deep;
    border-radius: 24px;
    background-color: white;
    color: $deep;
    font-family: $main-font-family;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 20px;
    text-align: center;
    max-width: 50vw;
    padding: 0 1rem;
    white-space: nowrap;
  }
}

.plp-pagination {
  height: 3rem;
  margin: 1rem 0;
  ul {
    background-color: rgba($medium, 0.08);
    border-radius: 24px;
    width: fit-content;
    height: inherit;
    margin: auto;
    align-items: center;

    padding: 0;
    li {
      list-style: none;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        font-family: $main-font-family;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        text-decoration: none;
        color: $deep !important;
        border: none;
        background-color: transparent;
        border-radius: 50%;

        &.active {
          background-color: $deep;
          color: $light !important;
        }
      }
    }
  }
}

.award-convert-page {
  .experience-commerce_assets-editorialRichText {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    max-width: 68rem;
    margin-inline: auto;
  }

  .experience-dynamic-productList {
    max-width: 68rem;
    margin-inline: auto;

    .product-grid > .row {
      gap: 5rem;

      @include media-breakpoint-up(md) {
        > div:nth-child(even) {
          .product-tile--amadeus {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }

  .experience-dynamic-carouselProductList {
    max-width: 68rem;
    margin-inline: auto;

    margin-bottom: 5rem;
  }

  .product-grid  {
    padding: 0;

    > div:first-of-type {
      display: none !important;
    }
  }

  .experience-commerce_layouts-accordion {
    margin-inline: -15px;
    background: $accordion-background 0% 0% no-repeat padding-box;

    @include media-breakpoint-up(md) {
      margin-inline: 0;
    }
  }
}
