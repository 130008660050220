@import "menu";

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

header {
  display: block;
  position: sticky;
  top: 0;
  z-index: 999;
}
header nav {
  background-color: $primary;
  ul {
    padding: 0;
    //margin-left: -1.5rem;
    @include media-breakpoint-up(md) {
      margin: auto;
    }
  }
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  height: 73px;
  width: 100%;
  max-width: 1440px;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    height: 80px;
  }

  .logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: 1099;
    width: 9rem;
    img {
      height: 2rem;
    }
    &.dropdown-hide {
      position: relative;
      z-index: auto;
    }
    padding-left: 15px;
    @include media-breakpoint-up(md) {
      padding: 0;
      gap: 12px;
      position: relative;
      width: auto;
      top: unset;
      gap: 10px;
      img {
        height: 33px;
      }
    }
  }
  .login-container {
    a {
      border-radius: 32px;
      border: none;
      padding: 14px;
      color: $light;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 1.2;
      text-align: center;
      width: auto;
      padding: 14px 1.5rem;
      background-color: transparent;
      margin: auto;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      &:hover{
        color: $light;
      }

      &.primary {
        background-color: $light;
        color: $primary;
      }
      &.secondary {
        margin-left: 2rem;
        border: 2px solid $light;
        &.link-cta{
          border: none;
          margin-left: 1rem;
        }

      }
    }
  }
  .customer-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    @include media-breakpoint-up(md) {
      gap: 1.5rem;

    }
    .header-order {
      .gift-icon {
        padding-bottom: 4px;
      }
    }
  }
  .customer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.5rem;
    width: max-content;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-right: 0.5rem;
    @include media-breakpoint-up(md) {
      margin-right: 0;
  }
    .customer-info {
      color: $light;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 20px;
      text-align: right;
      text-transform: capitalize;
      & p {
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        margin: 0;
      }
    }
    .customer-icon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: $celeste-chiaro;
      color: $deep;
      font-family: $main-font-family;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::after{
      display: none;
    }
  }
  .white-arrow__down {
    margin-left: 5px;
    background: url("../images/arrow-down.svg") no-repeat center center;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
    @include media-breakpoint-down(md) {
        display: inline-block;
    }
    &.rotate {
        transform: rotate(180deg);
    }
}
  .dropdown-customer{
    width: 15rem;
    width: 15rem;
    top: 3.75rem;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
    left: unset;
    right: 0;
    background-color: #fefefe;
    padding: 1.5rem ;
    border-radius: 10px;
    font-size: 1rem;
    transition: all 0.25s ease-in-out;
    &:after {
      display: none;
  }

    a,
    span{
      padding: 0;
      white-space: normal;
    }
    .action{
      font-weight: 600;
      &:hover{
        color: $primary;
      }
    }


    &.is-open  {
      display: block;
    }
  }
  .mobile-login {
    border-top: 1px solid #e9e9eb;
    position: absolute;
    bottom: 6rem;
    left: 0;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    padding: 1.5rem;
    &.registration-disabled{
      bottom: 12.5rem;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }
    a {
      width: 100%;
      background-color: $primary;
      color: $light;
      padding: 18px;
      &.primary {
        background-color: $light;
        color: $primary;
        border: 1px solid $primary;
      }
    }
  }
}

.navbar-header {
  .user,
  .country-selector,
  .search,
  .minicart {
    display: inline-block;
    margin: 1.125em 0 0 0.5em;
  }

  .user,
  .country-selector,
  .minicart,
  .navbar-toggler {
    line-height: 2.25em; /* 36/16 */
    height: auto;
  }

  .navbar-toggler {
    font-size: 1.6em;
    width: auto;
    color: $light;
  }

  .user {
    position: relative;

    .popover {
      position: absolute;
      display: none;
      padding: 1em;
      top: 85%;
      left: 0;

      a {
        white-space: nowrap;
        margin-bottom: 0.5em;
      }

      &::before {
        left: 1.5rem;
      }

      &::after {
        left: 1.5rem;
      }

      &.show {
        display: block;
      }
    }
  }
}

.brand {
  position: relative;
  display: block;

  img {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    width: 9rem;
    padding-right: 5px;
    padding-left: 15px;
    #skyteam {
      height: 33px;
    }
  }
}

.main-menu {
  width: 100%;
  .navbar .close-menu button,
  .navbar .close-button button {
    background-color: transparent;
    border: 0;
    color: $light;
    margin: 0.5rem;
    -webkit-appearance: none;
  }
  .close-button {
    margin-left: auto; 
    margin-right: 0.5rem; 
  }
  .back {
    margin-right: 1rem; 
  }
}

.header-banner {
  background-color: $darker-gray;
  text-align: center;
  color: $white;

  .close-button {
    width: 1.5em + $banner-padding * 2;

    .close {
      opacity: 1;
      color: $white;
      width: 100%;
      height: 100%;
      background-color: #{var(--skin-primary-color-1)};
    }
  }

  .content {
    margin-right: 1.5em;
    padding-top: $banner-padding;
    padding-bottom: $banner-padding;
  }
}

.minicart {
  margin-top: 0.1875em; /* 3/16 */
  vertical-align: top;

  .minicart-icon {
    font-size: 1.5em;
  }

  a.minicart-link {
    &:hover {
      text-decoration: none;
    }
  }

  .minicart-quantity {
    background-color: #{var(--skin-primary-color-1)};
    border-radius: 50%;
    width: 1.25em; /* 20/16 */
    height: 1.25em; /* 20/16 */
    line-height: normal;
    display: inline-block;
    text-align: center;
    font-size: 0.8125em; /* 13/16 */
    position: relative;
    top: -0.9375em; /* 15/16 */
    left: -0.9375em; /* 15/16 */
    color: $white;
  }
}

a.normal {
  color: #{var(--skin-primary-color-1)};
  text-decoration: underline;
}

.dropdown-country-selector {
  @include media-breakpoint-up(md) {
    left: auto;
    right: 0;
    padding: 0.5rem;
    box-shadow: $dropdown-shadow;
    transform: translate(-1rem, -0.25rem);
    border: 1px solid #e8e8ea;
    min-width: 6rem;
  }
}

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  display: none;
}

.valid-cookie-warning {
  background-color: #{var(--skin-link-color-1)};
  color: $white;
  white-space: nowrap;

  p {
    margin-top: 0;
    margin-bottom: 0.2em;
    padding-right: 2em;
  }
}

.simple-icon {
  padding-bottom: 4px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
    filter: brightness(0) invert(1);
  }
}
.assistace-container{
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #0B2340;
  font-weight: bold;
  .simple-icon{
    margin-right: 10px;
  }
}
.country-selector {
  padding-bottom: 0.75rem;
  #dropdownCountrySelector{
    width: 100%;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 20px;
    color: #0B2340;
    font-weight: bold;
    text-transform: uppercase;
    .simple-icon{
      margin-right: 10px;
    }
    @include media-breakpoint-up(md) {
      width: unset;
      height: unset;
      padding: 0.5rem;
    }

}
  .dropdown-item {
    padding: 0;
    font-family: $main-font-family;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1.95;
    text-align: center;
    &:hover {
      background: transparent;
    }
    i {
      margin-right: 0.25rem;
    }
  }
  .country-selector-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 8px;
    padding: 0.5rem;
    margin-top: 0.75rem;
    margin-right: 1rem;
    &:hover,
    &:focus,
    &:active {
      background-color: #2987d6;
    }
    span {
      color: $white;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 1.5;
      text-align: center;
    }
  }
}

.flag-rounded {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-position: center;
  background-size: cover;
}

.navbar-toggler {
  color: $light;
  padding: 0;
  &:focus {
    outline: none;
  }
}
