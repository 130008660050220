$breakpoint-name: "sm";
$breakpoint-name: "sm" !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
  border: solid $light;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin-right: 2px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

@mixin caret-right() {
  border: solid $primary;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 1rem;
  margin: 4px 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

@each $size in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($size) {
    .menu-toggleable-left.navbar-toggleable-#{$size} {
      position: fixed;
      left: -100%;
      top: 0;
      bottom: 0;
      display: block;
      max-width: 100%;

      &.in {
        min-width: 50%;
        left: 0;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .menu-toggleable-left.navbar-toggleable-sm {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    display: block;
    max-width: 100%;
    &.in {
      min-width: 50%;
      left: 0;
    }
  }
}

.navbar-nav{
  padding: 10px 15px;
  @include media-breakpoint-up(md) {
padding: 0;
  }
}
.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0;

  @include media-breakpoint-up($next-breakpoint) {
    .navbar-nav .nav-item + .nav-item {
      margin-left: 0;
    }

    .navbar-nav .nav-link {
      padding: 0.8rem;

      @include media-breakpoint-up(lg) {
        margin: 0.8rem;
        white-space: nowrap;
        padding: 0;
      }
      white-space: nowrap;
    }
  }
}

.navbar-expand-md .navbar-nav.nav-center {
  justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
  justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
  justify-content: end;
}
.nav-item {
  color: $dark;
  font-family: $main-font-family;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 18px 0;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.32);
  text-transform: uppercase;
  line-height: 1.4;
  color: $deep;
  cursor: pointer;
  height: 56px;
  @include media-breakpoint-up(lg) {
    position: relative;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0;
    text-transform: capitalize;
    border: none;
    height: unset;
    .dropdown-toggle {
      padding-left: 1rem;

      &::after {
        border: none;
        display: block;
      }
    }
  }
}
.modal-background {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}
.dropdown-toggle::after {
  display: block;
  margin-left: 0;
  content: "";
}

.nav-item .nav-link {
  @include media-breakpoint-up(lg) {
    &::after {
      content: "";
      display: block;
      background: #ffffff;
      width: calc(100% - 1.6rem);
      margin-left: 0;
      height: 0.1em;
      position: absolute;
      left: 13px;
      bottom: 10px;
      transition: opacity 300ms, transform 300ms;
      transform-origin: center;
      opacity: 0;
      transform: scale(0);
    }
  }
}
.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  @include media-breakpoint-up(lg) {
    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }
}

@include media-breakpoint-up(lg) {
  .nav-item > .nav-link {
    color: $white;
  }
}

.main-menu.menu-toggleable-left {
  @include media-breakpoint-down(md) {
    background-color: $white;
    z-index: 4;
  }
}
.main-menu .container {
  max-width: 100%;
}

.menu-toggleable-left {
  .close-menu {
    height: 72px;
    background-color: $primary;
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
    h1 {
      color: $light;
      font-family: $main-font-family;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 28px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;

    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .menu-group {
    flex: 0 0 100%;
    height: 100vh;
    position: relative;
    @include media-breakpoint-up(lg) {
      height: unset;
      margin-top: 6px;
    }
  }

  li > .close-menu {
    margin-right: 0;
    margin-top: -0.9rem;
    padding: 2px 0;
    margin-left: 0;
  }

  @include media-breakpoint-down(md) {
    .bg-inverse {
      background-color: transparent;
      color: $grey7;
    }
  }

  &.in {
    @include media-breakpoint-down($prev-breakpoint) {
      right: 0;
      margin-right: 1.25em; /* 20/16 */
    }

    @include media-breakpoint-down(md) {
      .nav-item + .nav-item {
        border-top: 1px solid $grey2;
      }

      .dropdown {
        display: block;
        position: static;
        color: #0B2340;
        font-family: $main-font-family;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-transform: uppercase;
        padding: 10px 0;
      }

      .dropdown-toggle {
        position: relative;
        padding-left: 1rem;

        &::after {
          top: 6px;
          @include caret-right();
        }
      }
      .dropdown-item {
        ul {
          .dropdown-item {
            &::after {
              content: '';
              @include caret-right();
            }
          }
        }
      }

      .nav-item .nav-link {
        padding: 0;
        height: 100%;
        align-items: center;
        display: flex;
      }

      .show > .dropdown-menu {
        left: 0;
        padding: 0 15px;
      }

      .dropdown-menu {
        position: absolute;
        left: -100%;
        top: 4px;
        width: 100%;
        height: 100%;
        border: 0 none;
        display: block;
        overflow-y:scroll;
        overflow-x:hidden;
      }
    }
  }
}

.multilevel-dropdown {
  .show > .dropdown-menu {
    @include media-breakpoint-up(lg) {
      & > .dropdown-item {
        padding: 0;
      }
    }
    & > .dropdown-item {
      border-bottom: none;
    }
    .dropdown-item {
      & .title {
        color: #666666;
        font-family: $main-font-family;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 16px;
        padding: 0.5rem 0;
        border-bottom: 1px solid #e9e9eb;
        text-transform: uppercase;
      }
    }
    @include media-breakpoint-up(lg) {
      .dropdown-item {
        & .title {
          border: none;
          padding: 0 0 0.25rem 0;
        }
      }
      display: block;
      background-color: $light;
      box-sizing: border-box;
      border-radius: 8px;
      top: 95%;
      display: flex;
      padding: 25px;
      box-shadow: $new-dropdown-shadow;
      transform: translateX(-1rem);
      border: 1px solid #e8e8ea;

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: transparent;
      }
      .dropdown-item {
        & .secondlev-separator {
          height: 1px;
          border-top: 1px solid $medium;
          opacity: 0.16;
        }
        & .title {
          color: $dark-gray;
          font-family: $main-font-family;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.4px;
          line-height: 2.33;
          cursor: default;
        }
      }
    }
  }

  .dropdown-item.dropdown > .dropdown-toggle::after {
    content: "";
  }

  .dropdown-menu > .dropdown > .dropdown-menu {
    @include media-breakpoint-up($next-breakpoint) {
      top: -0.65em;
      left: 99%;
    }
  }

  .navbar > .close-menu > .back {
    display: none;
  }

  .close-menu .back {
    position: relative;
    .caret-left {
      @include caret-left();
    }
  }

  .dropdown-item {
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #e9e9eb;
    &:active,
    &:focus {
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0.5rem 0;
      margin-right: 2rem;
      border: none;
      &:hover,
      &:focus {
        text-decoration: none;
        //padding: 0.5rem 0;
      }
    }
    .dropdown-link {
      text-transform: uppercase;
      @include media-breakpoint-up($next-breakpoint) {
        color: #0B2340;
        font-family: $main-font-family;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 22px;
        margin-top: 10px;
        text-decoration: none;
        text-transform: capitalize;
      }
    }

    &.top-category {
      font-weight: bold;

      > .nav-link {
        padding-left: 0;
      }
    }
  }
}
.dropdown-item {
  &:focus,
  &:active,
  &:hover {
    color: $deep;
    text-decoration: none;
    background-color: transparent;
  }
}
