@charset "UTF-8";

@import "variables";

@import "font-awesome/scss/font-awesome";
@import "flag-icon";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap");
@import "~base/bootstrap-custom-import";
@import "~base/bootstrapOverrides";
@import "./bootstrapOverrides";
@import "~base/utilities/responsiveUtils";
@import "~base/components/common";
@import "~base/components/hero";
@import "~base/components/headers";
@import "~base/components/notification";
@import "~base/components/pricing";
@import "~base/components/tabs";
@import "~base/components/breadcrumb";
@import "./components/spinner";
@import "~base/miniCart";
@import "~base/components/tooltip";
@import "~base/components/headerSearch";

@import "./search.scss";
@import "skin/skinVariables";
@import "components/header";
@import "components/productCommon";
@import "components/footer";
@import "components/productTiles";

@import "slick-carousel/slick/slick.scss";

h2 {
    color: $grey70;
    font-family: $main-font-family;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36px;
    border: none;
}

#maincontent {
    max-width: 1440px;
    margin: auto;
}

body {
    .page {
        overflow-y: unset !important;
        overflow-x: hidden !important;
    }
}
