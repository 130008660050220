@import "bootstrap/scss/functions";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts" !default;

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/" !default;


// Palette colors
// Brand
$primary: #0171CF !default;
$deep: #0C2340 !default;
$celeste: #92C1E9 !default;
$celeste-chiaro: #C2D7EE;
$green-ita : #00693E !default;
$red-ita : #BE210C !default;

// Utility 
$dark: #000000 !default;
$dark-gray: #555555 !default;
$medium: #72727F !default;
$light: #FFFFFF !default;

// Background
$bg1 : #FAFAFA !default;
$bg2: #F3F8FF !default;
$bg3: #F4F4F4 !default;
$border1: #DEDEDE !default;

// UI

$primary-inactive : rgba($primary,0.64) !default;
$primary-disabled : rgba($primary,0.32) !default;
$primary-seperator : rgba($primary,0.16) !default;
$primary-background : rgba($primary,0.08) !default;

$dark-inactive : rgba($dark,0.64) !default;
$dark-disabled : rgba($dark,0.32) !default;
$dark-seperator : rgba($dark,0.16) !default;
$dark-background : rgba($dark,0.08) !default;

$medium-inactive : rgba($medium,0.64) !default;
$medium-disabled : rgba($medium,0.32) !default;
$medium-seperator : rgba($medium,0.16) !default;
$medium-background : rgba($medium,0.08) !default;

$light-inactive : rgba($light,0.64) !default;
$light-disabled : rgba($light,0.32) !default;
$light-seperator : rgba($light,0.16) !default;
$light-background : rgba($light,0.08) !default;

// Shadow 
$card-shadow : 0 4px 8px 0 rgba(0,0,0,0.04);
$bar-shadow: 0 8px 12px 0 rgba(0,0,0,0.04);
$dropdown-shadow: 0 12px 20px 0 rgba(0,0,0,0.06);
$new-dropdown-shadow: 0 20px 24px 0 rgba(0, 0, 0, 0.06);
$dialog-shadow: 0 20px 24px 0 rgba(0,0,0,0.06);

// Strokes
$border-primary: 1px solid $primary;
$border-primary-inactive: 1px solid $primary-inactive;
$border-primary-disabled: 1px solid $primary-disabled;
$border-primary-seperator: 1px solid $primary-seperator;
$border-primary-background: 1px solid $primary-background;

$border-dark: 1px solid $dark;
$border-dark-inactive: 1px solid $dark-inactive;
$border-dark-disabled: 1px solid $dark-disabled;
$border-dark-seperator: 1px solid $dark-seperator;
$border-dark-background: 1px solid $dark-background;

$border-medium: 1px solid $medium;
$border-medium-inactive: 1px solid $medium-inactive;
$border-medium-disabled: 1px solid $medium-disabled;
$border-medium-seperator: 1px solid $medium-seperator;
$border-medium-background: 1px solid $medium-background;

$border-light: 1px solid $light;
$border-light-inactive: 1px solid $light-inactive;
$border-light-disabled: 1px solid $light-disabled;
$border-light-seperator: 1px solid $light-seperator;
$border-light-background: 1px solid $light-background;

$border-bold-primary: 2px solid $primary;
$border-bold-primary-inactive: 2px solid $primary-inactive;
$border-bold-primary-disabled: 2px solid $primary-disabled;
$border-bold-primary-seperator: 2px solid $primary-seperator;
$border-bold-primary-background: 2px solid $primary-background;

$border-bold-dark: 2px solid $dark;
$border-bold-dark-inactive: 2px solid $dark-inactive;
$border-bold-dark-disabled: 2px solid $dark-disabled;
$border-bold-dark-seperator: 2px solid $dark-seperator;
$border-bold-dark-background: 2px solid $dark-background;

$border-bold-medium: 2px solid $medium;
$border-bold-medium-inactive: 2px solid $medium-inactive;
$border-bold-medium-disabled: 2px solid $medium-disabled;
$border-bold-medium-seperator: 2px solid $medium-seperator;
$border-bold-medium-background: 2px solid $medium-background;

$border-bold-light: 2px solid $light;
$border-bold-light-inactive: 2px solid $light-inactive;
$border-bold-light-disabled: 2px solid $light-disabled;
$border-bold-light-seperator: 2px solid $light-seperator;
$border-bold-light-background: 2px solid $light-background;

$white: #fff !default;
$black: #000 !default;
$blue: #0070d2 !default;
$green: #008827 !default;
// primary is replaced by css variable --skin-primary-color-1
$primary: #00a1e0 !default;
$red: #c00 !default;
$success: $green !default;
$danger: $red !default;
$light-blue: #7ed0ee !default;

// Consolidated values
$grey1: #f9f9f9 !default;
$grey2: #eee !default;
$grey3: #ccc !default;
$grey4: #999 !default;
$grey5: #666 !default;
$grey6: #444 !default;
$grey7: #222 !default;
$grey8: #333 !default;
$grey70: #2E2E38;
$grey-transparent-1: rgba(0, 0, 0, 0.65) !default;
$grey-transparent-2: rgba(0, 0, 0, 0.25) !default;

$light-gray: $grey1 !default;
$slightly-darker-gray: $grey4 !default;
$dark-gray: $grey6 !default;
$darker-gray: $grey7 !default;
$horizontal-rule-grey: $grey3 !default;
$product-number-grey: $grey3 !default;
$horizontal-border-grey: $grey4 !default;
$menu-link: $grey6 !default;
$close-menu-bg: $grey2 !default;
$link-color: $dark-gray !default;
$hr-border-color: $grey3 !default;

$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 992px,
    xl: 1200px,
    tv: 1440px
) !default;

$container-max-widths: (
    md: 720px,
    lg: 940px,
    xl: 1440px
) !default;

$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;

$state-danger-text: #fff !default;
$alert-success-text: #fff !default;

// Font sizes
$base16-14px: 0.875em !default;

// Bootstrap overrides
$body-bg: $grey1 !default;
$card-cap-bg: $white !default;

// Tabs
$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-active-link-hover-bg: transparent !default;

// breadcrumb
$breadcrumb-bg: transparent !default;

// table border
$table-border-color: $grey3 !default;

// Font Family
$main-font-family: "Lato", sans-serif;

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;

// Full-Award - Amadeus
$banner-background-color: #0B2340CC;

// Forms
$primary-text-color: #0B2340;
$radio-border: #666666;
$empty-input-border: #A4A4AD;
$passenger-item-border: #D1D1D5;
$desktop-popup-border: #72727F29;
$desktop-popup-shadow: #00000029;
$desktop-hover-border: #0271CF;
$travel-type-background: #EDF4FB;
$error-color: #D71B19;
$tile-border: #E8E8E8;
$button-background: #F8F8F9;
$accordion-background: #F8F8F9;
