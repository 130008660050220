@import "productCommon";

.product-tile {
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 1rem;
  height: 368px;
  &.amadeus{
    height: 300px;
  }
  background-color: $light;
  position: relative;

  cursor: pointer;
  @include media-breakpoint-up(lg) {
    height: 496px;
  }
  @include media-breakpoint-up(tv) {
    height: 520px;
    &.amadeus{
      height: 400px;
    }
  }

  &--amadeus {
    height: auto;
    display: flex;
    flex-direction: column;
    cursor: initial !important;

    @include media-breakpoint-up(md) {
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px $desktop-popup-shadow;
      border-radius: 0.5rem;
      flex-direction: row;
      padding: 1.5rem;
      gap: 1rem;
    }

    &__img-wrapper {
      max-height: 12.5rem;
      overflow: hidden;
      display: flex;

      @include media-breakpoint-up(md) {
        max-height: initial;
        min-width: calc(50% - 0.5rem);
        flex-grow: 1;
      }

      .image-container {
        width: 100%;
        max-height: 100%;
        display: flex;
        flex-grow: 0;
        height: auto !important;
        margin: 0 !important;

        @include media-breakpoint-up(md) {
          display: flex;
          width: 100%;
          height: 100% !important;
          position: relative;
        }

        img {
          border-radius: 0.5rem 0.5rem 0 0 !important;

          @include media-breakpoint-up(md) {
            border-radius: 0.5rem !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    &__desc-wrapper {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      gap: 1rem;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        flex-grow: 1;
      }
    }

    &__locations-wrapper {
      display: flex;
      height: 1.875rem;
      align-items: center;
      gap: 0.5rem;

      @include media-breakpoint-up(md) {
        width: 100%;
      }

      span {
        font: normal normal bold 1.5rem / 2rem $main-font-family;
        letter-spacing: 0px;
        color: $primary-text-color;
        text-transform: capitalize;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
      }
    }

    &__travel-type {
      display: flex;

      span {
        text-align: center;
        font: normal normal bold 0.75rem / 1rem $main-font-family;
        letter-spacing: 0px;
        color: $primary-text-color;
        min-width: 5.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $travel-type-background 0% 0% no-repeat padding-box;
        border-radius: 0.5rem;
        padding: 0.25rem;
      }
    }

    &__travel-description {
      font: normal normal normal 1rem / 1.5rem $main-font-family;
      color: $primary-text-color;
      width: 100%;
    }

    &__points {
      color: $primary;
      font: normal normal bold 1.125rem / 1.5rem $main-font-family;

      b {
        font: normal normal bold 1.5rem / 1.5rem $main-font-family
      }
    }

    &__select-button {
      background: $primary 0% 0% no-repeat padding-box;
      border-radius: 0.5rem;
      border: none;
      color: $white;
      font: normal normal bold 1rem / 1.25rem $main-font-family;
      padding: 0.75rem;
      max-width: 12.375rem;

      @include media-breakpoint-up(md) {
        min-width: 12.375rem;
      }
    }
  }

  .product-tile-global-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  .tile-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    height: 52%;
    overflow: hidden;
    .amadeus-points{
      color: $primary;
    }
    .amadeus-brand-container{
      background-color: #f6f6f6;
      width: 3.5rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      float: right;
      img{
        width: 100%;
        padding: 4px;
      }
    }

    .color-swatches {
      min-height: 2.25em;

      .product-tile-color-label {
        cursor: pointer;
        font-size: 1em;

        @include media-breakpoint-down(md) {
          font-size: 0.9375em;
        }

        @include media-breakpoint-down(sm) {
          font-size: 0.8125em;
        }
      }
    }

    .price {
      font-size: 1.125em;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        font-size: 1.0625em;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1em;
      }

      .tiered {
        font-size: 0.875em;

        .value {
          font-weight: bold;
        }
      }
    }

    .coming-soon-tile {
      text-align: center;
    }

    .pdp-link {
      line-height: 1.2;

      span {
        font-size: 1em;
        text-decoration: none;

        @include media-breakpoint-down(md) {
          font-size: 0.9375em;
        }

        @include media-breakpoint-down(sm) {
          font-size: 0.8125em;
        }
      }
    }

    .ratings {
      font-size: 0.9em;
    }
  }

  .image-container {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    height: 48%;

    .tile-image {
      display: flex;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 1rem 1rem 0 0;
    }
  }

  .brand-image-container {
    margin-bottom: .5rem;
    .brand-image {
      height: 1.5rem;
      max-width: inherit;
      @include media-breakpoint-up(lg) {
        height: 2.5rem;
      }
    }
  }
  .product-name-container {
    margin-bottom: 0.5rem;
    h3 {
      color: #2e2e38;
      font-family: $main-font-family;
      border: none;
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.33px;
      line-height: 20px;
      margin: 0;

      @include media-breakpoint-up(lg) {
        font-size: 24px;
        letter-spacing: 0.4px;
        line-height: 32px;
      }
    }
  }

  .product-description-container {
    max-height: 40px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      font-size: 16px;
      letter-spacing: 0.4px;
      line-height: 20px;
    }
    p {
      color: #2e2e38;
      font-family: $main-font-family;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 18px;
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(xl) {
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 20px;
      }
    }
  }
  .product-link-container {
    .product-tile-link {
      font-family: $main-font-family;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.36px;
      line-height: 24px;
      color: $primary;
    }
    .product-tile-link:hover {
      text-decoration: none;
    }
  }

  .swatches {
    .product-tile-link {
      text-decoration: none;
    }
  }
}

#chooseBonusProductModal {
  .modal-footer {
    .container {
      margin-left: 0;
      width: 100%;
      margin-right: 0;
    }
  }

  .select-cbp-container {
    margin-top: auto;
    margin-bottom: auto;
  }

  .product-name-wrapper {
    width: 100%;
  }

  .bonus-quantity,
  .bonus-option {
    margin-top: 0.938em;
  }

  .bonus-quantity-select {
    min-width: 5em;
  }

  .select-bonus-product {
    margin-top: 1em;
  }

  .selected-pid {
    border: 1px solid $grey3;

    .bonus-product-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .bonus-product-price {
    text-align: center;
    margin-top: 1em;
  }
}


.product-tile--amadeus--small {
  display: flex;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid $tile-border;
  border-radius: 0.5rem;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    flex-direction: column;
  }

  &__img-wrapper {
    width: 32%;

    @include media-breakpoint-up(md) {
      width: 100%;
      height: 10.5rem;
      display: flex;
    }

    .image-container  {
      display: flex;
      height: 100%;
      width: 100%;

      @include media-breakpoint-up(md) {
        height: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__desc-wrapper {
    flex-grow: 1;
    padding: 0.75rem 1rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.625rem;
    align-items: baseline;
    justify-content: space-between;
  }

  &__locations-wrapper {
    font: normal normal bold 1.125rem / 1.625rem $main-font-family;
    letter-spacing: 0px;
    color: $primary-text-color;
    text-transform: capitalize;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__travel-type {
    font: normal normal normal 0.875rem / 1.1875rem $main-font-family;
  }

  &__points {
    color: $primary;
    font: normal normal bold 0.75rem / 1.625rem $main-font-family;

    b {
      font-size: 1.375rem;
    }
  }
}

.product-tile--amadeus--small__wrapper {
  @include media-breakpoint-up(md) {
    flex-grow: 1;
    display: flex;
  }
}