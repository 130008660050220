@import "../variables";

.custom-select-container {
    .s-hidden {
        visibility: hidden;
    }

    .select {
        cursor: pointer;
        display: inline-block;
        position: relative;
    }
    &.sorting-select {
         .styledSelect {
            display: flex;
            align-items: center;
            padding-left: 10px;
            height: 40px;
            width: auto;
            border: 2px solid $deep;
            border-radius: 24px;
            color: $deep;
            font-family: $main-font-family;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.4px;
            line-height: 20px;
        }
         .options {
            transform: translate(-0.75rem, 0rem);
        }
    }

    .styledSelect {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
        color: $grey70;
        font-family: $main-font-family;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 20px;
    }

    .styledSelect:after {
        content: "";
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-color: $medium transparent transparent transparent;
        position: absolute;
        top: 50%;
        right: 10px;
    }

    .options {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 99;
        margin: 0;
        padding: 4px 0;
        width: calc(100% + 1rem);
        transform: translate(-1rem, 0.75rem);
        border-radius: 8px;
        list-style: none;
        background-color: $light;
        box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.06);
        li {
            font-family: $main-font-family;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.4px;
            line-height: 20px;
            padding: 14px 1rem;
            margin: 0 0;
            &.activeItem::after {
                content: "";
                width: 0;
                height: 0;
                font-size: 20px;
                color: $medium;
                font-weight: 500;
                position: absolute;
                top: 18px;
                right: 1rem;
                transform: scaleX(-1) rotate(-42deg);
                content: "L";
            }
            &.activeItem,
            &:hover {
                position: relative;
                background-color: rgba(#3fafff, 0.08);
                color: $grey70;
            }
        }
    }
}
.card-select {
    .select {
        transform: translateY(1rem);
        width: 100%;
    }
  }