footer {
    width: 100%;
    font-family: $main-font-family;
    margin-top: 2rem;

    .extra-margin-top {
        margin-top: 50px;
    }
    .horiz-separator-top-blue {
        border-top: 1px solid #2987d6;
        padding-top: 15px;
    }
    .row {
        padding: 0 15px;
    }
    .blue-info {
        background: url("../images/info.svg") no-repeat center center;
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
    .contactus-tooltip {
        position: absolute;
        box-shadow: 0 12px 20px 0 rgba(8, 8, 8, 0.06);
        border: 1px solid #e8e8ea;
        border-radius: 24px;
        background-color: #ffffff;
        padding: 10px 15px;
        right: 1px;
        top: -50px;
    }
    .contactus-tooltip-arrowdown {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #ffffff;
        position: absolute;
        right: 20px;
        bottom: -10px;
    }

    .white-arrow__down {
        display: none;
        margin-left: 5px;
        background: url("../images/arrow-down.svg") no-repeat center center;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        opacity: 0.6;
        transition: all 0.25s ease-in-out;
        @include media-breakpoint-down(md) {
            display: inline-block;
        }
        &.rotate {
            transform: rotate(180deg);
        }
    }

    .empty-column {
        margin: 0 0.5rem;
    }
    // desktop
    @include media-breakpoint-down(md) {
        .extra-margin-bottom-mob {
            margin-bottom: 20px;
        }
        .empty-column {
            display: none;
        }
        .flex-reverse-mob {
            flex-direction: column-reverse;
        }
    }

    .mod-contacts {
        background: #ebf4fc;
        .contactus-airways {
            display: flex;
            align-items: center;
            padding-top: 25px;
            padding-bottom: 25px;
        }

        .contactus-text {
            color: #000000;
            font-family: $main-font-family;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            margin-right: 40px;
        }

        .contactus-currentCountry,
        .contactus-abroad {
            position: relative;
            display: flex;
            align-items: center;
            background-color: #ffffff;
            border-left: 4px solid $primary;
            padding: 8px 20px;
            margin-right: 40px;
            .contactus-abroad--text,
            .contactus-currentCountry--text {
                color: #555555;
                font-family: $main-font-family;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                margin-right: 5px;
            }
            .contactus-abroad--number,
            .contactus-currentCountry--number {
                color: #000000;
                font-family: $main-font-family;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
            }
        }
        .contactus-info-icon {
            margin-left: 20px;
        }
        @include media-breakpoint-down(md) {
            .contactus-airways {
                flex-direction: column;
            }
            .contactus-text {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
            .contactus-currentCountry {
                margin-bottom: 10px;
            }
            .contactus-abroad,
            .contactus-currentCountry {
                width: 100%;
                margin-right: 0;
                justify-content: space-between;
            }
        }
    }

    .mod-pre {
        border-top: 4px solid #0c2340;
        background: $primary;
        padding-top: 25px;
        padding-bottom: 30px;

        .logobox-airways {
            display: flex;
            padding-right: 20px;
            padding-left: 15px;
        }

        @include media-breakpoint-down(md) {
            .logobox-airways {
                padding: 0;
            }
        }
    }

    .mod-footer {
        background: $primary;
        padding-top: 26px;
        padding-bottom: 31px;

        h4.footer-firstlev-title {
            color: #ffffff;
            font-family: $main-font-family;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.4px;
            line-height: 16px;
            padding-bottom: 4px;
            border-bottom: 1px solid #2987d6;
            text-transform: uppercase;
            &.collapsed {
                display: none;
                @include media-breakpoint-down(md) {
                    display: flex;
                }
            }
        }

        .footer-secondlev-list {
            padding: 0;
            list-style: none;
        }

        .footer-secondlev-item {
            margin-top: 15px;

            a {
                color: #ffffff;
                font-family: $main-font-family;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.4px;
                line-height: 20px;
            }
        }
        @include media-breakpoint-down(md) {
            padding-bottom: 15px;

            h4.footer-firstlev-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0;
            }
            h4.footer-firstlev-title span {
                display: inline-block;
            }
            .footer-secondlev-list {
                margin-bottom: 0;
            }
        }
    }

    .mod-sociallinks {
        background: $primary;

        .downloadapp-airways {
            display: flex;
            justify-content: left;
            a {
                margin-right: 20px;
            }
            img {
                height: 32px;
            }
        }

        .sociallinks-airways {
            display: flex;
            justify-content: right;

            img {
                height: 24px;
            }
            a {
                margin-left: 25px;
            }
            @include media-breakpoint-down(md) {
                justify-content: left;
                margin-bottom: 20px;
                a {
                    margin-left: 0;
                    margin-right: 25px;
                }
            }
        }
    }

    .mod-copyright {
        background: #0171cf;
        padding: 15px 0 25px 0;
        .no-gutters {
            margin-right: 0;

            .copyright-content {
                color: #fff;
                font-family: "Raleway", sans-serif;
                font-weight: 300;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.05em;
                text-align: center;
                @include media-breakpoint-down(md) {
                    color: #ffffff;
                    font-family: $main-font-family;
                    font-size: 12px;
                    letter-spacing: 0.4px;
                    line-height: 16px;
                    text-align: left;
                }
            }
        }
    }
}
